<template>
  <div class="bg-dark position-absolute rounded shadow-sm actions" style="border: 1px solid #696969;bottom: calc(100% - 0.5em); z-index: 100" :style="menuPosition">
    <div class="mx-2" v-if="postDeleted && didDeletePost">
      <button :disabled="readonly" class="btn btn-sm btn-link text-white" @click.prevent="restorePost(post.id)" v-tooltip="$t('js.tooltips.restore')" :key="'restore'">
        <i class="fas fa-trash-restore"></i>
      </button>
    </div>
    <div v-else-if="editing">
      <button :disabled="readonly" class="btn btn-sm btn-link text-white" @click.prevent="$emit('prepareEdit')" v-tooltip="$t('js.actions.submit')" :key="'cancel'">
        <i class="fas fa-check"></i>
      </button>
      <button :disabled="readonly" class="btn btn-sm btn-link text-white" @click.prevent="$emit('cancelEdit')" v-tooltip="$t('js.actions.cancel')" :key="'submit'">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="mx-2" v-else-if="!postDeleted">
      <button v-if="isAdmin" :disabled="readonly" class="btn btn-sm btn-link text-white" data-bs-toggle="modal" data-bs-target="#highlightModal" @click.prevent="setPostToHighlight(post)" v-tooltip="$t('js.tooltips.highlight')">
        <i class="fas fa-thumbtack"></i>
      </button>
      <button v-if="post.type === 'Candidacy' && isAdmin && !post.addedToBallot" :disabled="readonly" class="btn btn-sm btn-link text-white" @click.prevent="setPostToAddToBallot(post)"  v-tooltip="$t('js.tooltips.add_to_ballot')">
        <i class="fas fa-plus-circle"></i>
      </button>
      <button v-if="canEdit" :disabled="readonly" class="btn btn-sm btn-link text-white" @click.prevent="$emit('prepareEdit', post)" v-tooltip="$t('js.tooltips.edit')">
        <i class="fas fa-pen"></i>
      </button>
      <button v-if="itemOwner || isAdmin" :disabled="readonly" class="btn btn-sm btn-link text-white" @click.prevent="deletePost(post.id)" v-tooltip="$t('js.tooltips.delete')" :key="'delete'">
        <i class="fas fa-trash-alt"></i>
      </button>
    </div>

  </div>

</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex"

    export default {
        name: "PostActions",
        props: {
            post: Object,
            editing: false,
        },
        computed: {
          ...mapState(['chatUser', 'locales', 'readonly']),
          canEdit(){
            return this.itemOwner && !this.post.addedToBallot
          },
          itemOwner(){
            return this.chatUser.type === this.post.owner.type && this.post.owner.id === this.chatUser.id
          },
          didDeletePost(){
            return this.post.deleted_by.id && this.post.deleted_by.type && this.chatUser.type === this.post.deleted_by.type && this.post.deleted_by.id === this.chatUser.id
          },
          isAdmin(){
            return this.chatUser.type === 'Admin'
          },
          addCandidacyPath(){
            return '../contests/'+this.post.target_slide.contestId+'/options/simple_new?' + this.locales.map(locale => `option[title][${locale}]=${this.post.owner.name}`).join('&')
          },
          postDeleted(){
            return this.post.deleted_by && this.post.deleted_by.id
          },
          menuPosition(){
              return this.itemOwner ? 'right: 0.5em;' : 'left: 0.5em;'

          }
        },
        methods: {
            ...mapActions(['deletePost', 'restorePost']),
            ...mapMutations(['setPostToHighlight', 'setPostToAddToBallot']),
        },

    }
</script>