<template>
  <div class="toast rounded list-item show" :class="classes.wrapper" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header" :class="`${classes.header} ${body.length > 0 ? '' : 'border-bottom-0'}`">
      <strong class="me-auto">{{ header }}</strong>
      <button type="button" class="ms-2 mb-1 btn-close" @click="$emit('close')" aria-label="Close">
        <span class="sr-only" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div v-if="body.length > 0" class="toast-body" :class="classes.body" v-html="body">
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: {
    header: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    classes: {
      type: Object,
      default: () => ({wrapper: '', header: '', body: ''})
    },
  }


}
</script>